import React, { useEffect } from "react";
// import { HashRouter, Route, Routes } from 'react-router-dom';
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import "./App.css";
import AboutPage from "./components/aboutUsPage/AboutPage";
import RailwayDomain from "./components/landingPage/domains/RailwayDomain";
import CloudDomain from "./components/landingPage/domains/CloudDomain";
import SmazerProductPage from "./components/productsPage/SmazerProduct";
import SzephyrproductPage from "./components/productsPage/SzephyrProduct";
import SmampMaxProductPage from "./components/productsPage/SmampMaxProducts";
import AOS from "aos";
import "aos/dist/aos.css";
import "react-datepicker/dist/react-datepicker.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import SpaceDomain from "./components/landingPage/domains/SpaceDomain";
import PageNotFound from "./core/constants/PageNotFound";
import IOTDomain from "./components/landingPage/domains/IotDomain";
import ContactUsForm from "./components/productsPage/ContactUsForm";
import Header from "./components/landingPage/Header";
import PrivacyPolicy from "./components/privacyPolicy/PrivacyPolicy";
import { SZEPHYR_URL_PATH } from "./core/constants/Constant";
import RedirectApp from "./components/productQrCode/SzephyrRedirectApp";
import SzephyrRedirectApp from "./components/productQrCode/SzephyrRedirectApp";
import SmazerRedirectApp from "./components/productQrCode/SmazerRedirectApp";
import SmampmaxRedirectApp from "./components/productQrCode/SmampmaxRedirectApp";
import DefenceDomain from "./components/landingPage/domains/DefenceDomain";

function App() {
 useEffect(() => {
  AOS.init({
   duration: 1000, // Set the duration for animations
   easing: "ease-in-out",
   once: true,
   mirror: false,
  });
 }, []);

 return (
  <React.Fragment>
   <Router>
    <Routes>
     <Route path="/" exact element={<Header />} />
     {/* <Route path="/service-page" element={<ServicePage />} />
          <Route path="/getsupport-page" element={<GetSupportPage />} /> */}
     <Route path="/aboutus-page" element={<AboutPage />} />
     {/* <Route path="/smazer-product" element={<SmazerProductPage />} /> */}
     {/* <Route path="/szephyr-product" element={<SzephyrproductPage />} /> */}
     {/* <Route path="/vidyuRaksha-product" element={<SmampMaxProductPage />} /> */}
     <Route path="/contact-us" element={<ContactUsForm />} />
     <Route path="/iot" element={<IOTDomain />} />
     <Route path="/railway" element={<RailwayDomain />} />
     <Route path="/defence" element={<DefenceDomain />} />
     {/* <Route path='/cloud' element={<CloudDomain />} /> */}
     <Route path="/space" element={<SpaceDomain />} />
     <Route path="/privacy_policy" element={<PrivacyPolicy />} />
     {/* <Route path="/products/szephyrqr" element={<SzephyrRedirectApp />} /> */}
     {/* <Route path="/products/smazerqr" element={<SmazerRedirectApp />} /> */}
     {/* <Route path="/products/smampmaxqr" element={<SmampmaxRedirectApp />} /> */}
     <Route path="*" element={<PageNotFound />} />
     {/* <Route path="/privacy-policy" element={} /> */}
     {/* <Route path='/product-registration' element={<ProjectRegistration />} /> */}
     {/* <Route path='/product-registration-confirm' element={<ProjectRegConfirm />} />
          <Route path='/product-registration-form' element={<ProductRegistrationForm />} /> */}
     {/* <Route path='/spinner' element={<Spinner />} /> */}
    </Routes>
   </Router>
  </React.Fragment>

  // <div className="App">
  //   <header className="App-header">
  //     <img src={logo} className="App-logo" alt="logo" />
  //     <p>
  //       Edit <code>src/App.js</code> and save to reload.
  //     </p>
  //     <a
  //       className="App-link"
  //       href="https://reactjs.org"
  //       target="_blank"
  //       rel="noopener noreferrer"
  //     >
  //       Learn React
  //     </a>
  //   </header>
  // </div>
  // <>
  //    {/* <LandingPage /> */}
  //   <ServicePage />
  // </>
 );
}

export default App;
