import React from "react";
import "../../componentStyles/landingPageStyle/Domain.css";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { DOMAINS } from "../../core/constants/Constant";
import Defence from "../../assets/domain-defence.png";

function Domain() {
 const navigate = useNavigate();

 const navigateToDomain = (pathUrl) => {
  navigate(pathUrl);
 };

 const isTabletPortrait = useMediaQuery({
  query: "(min-width: 768px) and (max-width: 1024px) and (-webkit-min-device-pixel-ratio: 1)",
 });

 return (
  <React.Fragment>
   {/* <div className="radial-gradient-right"></div> */}
   <div id="domain_section">
    <div className="text-center domain_text" data-aos="fade-up">
     <span className="domain_text_alt">Domains</span> We Focus On
    </div>
    <div className="col-12 mt-4 d-md-flex flex-wrap justify-content-center align-items-center">
     {DOMAINS.map((contents, index) => (
      <div
       className="col-md-4 p-3 d-flex justify-content-center align-items-center"
       style={{ cursor: "pointer" }}
       data-aos="fade-up"
       data-aos-delay="100"
       key={index}
       onClick={() => navigateToDomain(contents.path)}
      >
       <div
        className="card custom-card-domain"
        style={{
         background: `url(${contents.logo})`,
         backgroundSize: "cover",
         backgroundRepeat: "no-repeat",
         backgroundPosition: "bottom",
        }}
       >
        <div className="card-body custom-card-body-domain pb-1">
         <h5 className="card-title domain_subhead m-0">{contents.name}</h5>
         {/* <p className="card-text text-white serviceProvie_content"></p> */}
        </div>
       </div>
      </div>
     ))}
    </div>

    {/* <div className="col-md-12 d-md-flex justify-content-center align-items-center">
     <div
      className="col-md-4 p-3"
      style={{ cursor: "pointer" }}
      data-aos="fade-up"
      data-aos-delay="100"
      onClick={() => navigateToDomain("/defence")}
     >
      <div
       className="card custom-card-domain"
       style={{
        background: `url(${Defence}) center center / cover no-repeat`, 
        height: "100%", 
       }}
      >
       <div className="card-body custom-card-body-domain pb-1">
        <h5 className="card-title domain_subhead m-0">Defence</h5>
       </div>
      </div>
     </div>
    </div> */}

    {/* {isTabletPortrait && (
          <div id="tablet_railway">
            {tabletModeDomains.map((domain) => (
              <div
                className="col-11 d-flex justify-content-center flex-column align-items-center mt-5"
                data-aos="fade-up"
                onClick={() => navigateToDomain("/iot")}
              >
                <h2 className="iot_text" data-aos="fade-up">
                  {domain.name}
                </h2>
                <div>
                  <img
                    src={domain.logo}
                    alt="IOT_img"
                    className="domain_images_iot"
                  />
                </div>
                <div className="iot_para text-center mt-3">
                  {domain.contents}
                </div>
              </div>
            ))}
          </div>
        )} */}
    {/* {!isTabletPortrait && (
          <div>
            <div
              className="container mt-5 rail_way"
              data-aos="fade-up"
              onClick={() => navigateToDomain("/iot")}
            >
              <div className="row d-flex justify-content-center align-items-center iot_effect">
                <div className="col-md-6 desktop_iot" id="2">
                  <img
                    src={iotLogo}
                    alt="IOT_img"
                    className="domain_images_iot"
                  />
                </div>
                <div className="col-md-6">
                  <h2 className="iot_text" data-aos="fade-up">
                    IOT / IIOT
                  </h2>
                  <div className="col-md-6 mt-5 mb-5 mobile_iot" id="2">
                    <img
                      src={iotLogo}
                      alt="IOT_img"
                      className="domain_images_iot"
                    />
                  </div>
                  <p
                    className="iot_para"
                    data-aos="fade-up"
                    data-aos-delay="400"
                  >
                    {IOT_CONTENT_LANDINGPAGE}
                  </p>
                </div>
              </div>
            </div>

            <div
              className="container mb-5"
              data-aos="fade-up"
              onClick={() => navigateToDomain("/cloud")}
            >
              <div className="row d-flex justify-content-center align-items-center cloud_effect">
                <div className="col-md-6">
                  <h2 className="cloud_text text-uppercase" data-aos="fade-up">
                    Cloud
                  </h2>
                  <div className="col-md-8 mt-5 mb-5 mobile_cloud" id="1">
                    <img
                      src={cloudLogo}
                      alt="cloud_image"
                      className="domain_images_cloud"
                    />
                  </div>
                  <p
                    className="cloud_para"
                    data-aos="fade-up"
                    data-aos-delay="400"
                  >
                    {CLOUD_CONTENT_LANDINGPAGE}
                  </p>
                </div>
                <div className="col-md-6 desktop_cloud text-center" id="1">
                  <img
                    src={cloudLogo}
                    alt="cloud_image"
                    className="domain_images_cloud"
                  />
                </div>
              </div>
            </div>
            <div
              className="container mb-5"
              data-aos="fade-up"
              onClick={() => navigateToDomain("/railway")}
            >
              <div className="row d-flex justify-content-center align-items-center cloud_effect">
                <div className="col-md-6">
                  <h2 className="railway_text" data-aos="fade-up">
                    Railway
                  </h2>
                  <div className="col-md-8 mt-5 mb-5 mobile_cloud" id="1">
                    <img
                      src={railwayLogo}
                      alt="Railway_Image"
                      className="domain_images_railway"
                    />
                  </div>
                  <p
                    className="cloud_para"
                    data-aos="fade-up"
                    data-aos-delay="400"
                  >
                    {RAILWAY_CONTENT_LANDINGPAGE}
                  </p>
                </div>
                <div className="col-md-6 desktop_cloud text-center" id="1">
                  <img
                    src={railwayLogo}
                    alt="Railway_Image"
                    className="domain_images_railway"
                    data-aos="fade-right"
                    data-aos-delay="100"
                  />
                </div>
              </div>
            </div>
            <div
              className="container mt-5 rail_way"
              data-aos="fade-up"
              onClick={() => navigateToDomain("/iot")}
            >
              <div className="row d-flex justify-content-center align-items-center iot_effect">
                <div className="col-md-6 desktop_iot" id="2">
                  <img
                    src={satelliteLogo}
                    alt="cloud_image"
                    className="domain_images_cloud"
                  />
                </div>
                <div className="col-md-6">
                  <h2 className="cloud_text text-uppercase" data-aos="fade-up">
                    Space
                  </h2>
                  <div className="col-md-6 mt-5 mb-5 mobile_iot" id="2">
                    <img
                      src={satelliteLogo}
                      alt="cloud_image"
                      className="domain_images_cloud"
                    />
                  </div>
                  <p
                    className="iot_para"
                    data-aos="fade-up"
                    data-aos-delay="400"
                  >
                    {SPACE_CONTENT_LANDINGPAGE}
                  </p>
                </div>
              </div>
            </div>
          </div>
        )} */}
   </div>
  </React.Fragment>
 );
}

export default Domain;
