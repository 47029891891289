import React, { useEffect, useState } from "react";
import { IoMdHome } from "react-icons/io";
import { MdClose } from "react-icons/md";
import { useDispatch } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import { closeMobileNav } from "../../redux/actions";
import logo from "../../assets/homePage/company_logo.png";

function MobileNavigationHeader(props) {
 const navigate = useNavigate();
 const dispatch = useDispatch();

 const handleCloseMobileNav = () => {
  dispatch(closeMobileNav());
 };

 const navigateHome = () => {
  navigate("/");
  window.location.reload();
 };

 useEffect(() => {
  // Close mobile navigation when the component mounts
  handleCloseMobileNav();
  // Event listener for window resize
  const handleResize = () => {
   // Close mobile navigation when the screen size changes
   handleCloseMobileNav();
  };

  // Add event listener for window resize
  window.addEventListener("resize", handleResize);

  // Cleanup the event listener on component unmount
  return () => {
   window.removeEventListener("resize", handleResize);
  };
 }, []);

 return (
  props.mobileActive && (
   <div className="mobile-nav-active mt-5">
    <nav id="navbar" className="navbar">
     <ul className="tab_nav_responsive">
      <li className="mt-3 mb-2 mx-2">
       <img
        src={logo}
        alt="company_logo"
        className="image-fluid company_logo"
        onClick={navigateHome}
        style={{ cursor: "pointer" }}
       />
      </li>
      <li style={{ listStyle: "none" }}>
       <NavLink to={"/"}>
        {/* <IoMdHome size={27} /> */}
        Home
       </NavLink>
      </li>
      {/* <li><a href="">Domain</a></li> */}
      <div className="mobile-nav-toggle" onClick={props.toggleClick}>
       {props.mobileActive ? <MdClose style={{ marginTop: "-10px" }} /> : ""}
      </div>
      {/* <li style={{ listStyle: "none" }}>
              <NavLink to={"/szephyr-product"}>sZephyr</NavLink>
            </li> */}
      {/* <li><NavLink to={"/service-page"}>Services</NavLink></li> */}
      {/* <li><NavLink to={"/smampmax-product"}>SmAmp Max</NavLink></li> */}
      {/* <li style={{ listStyle: "none" }}>
              <NavLink to={"/vidyuRaksha-product"}>VidyuRaksha</NavLink>
            </li>
            <li style={{ listStyle: "none" }}>
              <NavLink to={"/smazer-product"}>SmaZer</NavLink>
            </li> */}
      <li style={{ listStyle: "none" }}>
       <NavLink to={"/aboutus-page"}>About Us</NavLink>
      </li>
     </ul>
    </nav>
   </div>
  )
 );
}

export default MobileNavigationHeader;
