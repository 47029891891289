import React, { useEffect } from "react";
import { NavLink, Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import { useDispatch, useSelector } from "react-redux";
import Logo from "../../../assets/homePage/company_logo.png";
import { IoMdHome } from "react-icons/io";
import { MdOutlineShoppingCart } from "react-icons/md";
import { RxHamburgerMenu } from "react-icons/rx";
import MobileNavigationHeader from "../../../core/constants/MobileNavHeader";
import "./RailwayDomain.css";
import Footer from "../Footer";
import { toggleCloseDropdown, toggleDropdown, toggleMobileNav } from "../../../redux/actions";
import DropDownProducts from "../../../core/constants/DropdownProducts";
import AlternateFooter from "../AlternateFooter";
import { IoIosArrowForward, IoIosArrowDown } from "react-icons/io";
import PublicHeader from "../../../core/constants/PublicHeader";
import { RAILWAY_CONTENT_LANDINGPAGE } from "../../../core/constants/Constant";

function RailwayDomain() {
 const dispatch = useDispatch();
 const isMobileNavActive = useSelector((state) => state.isMobileNavActive);
 const isDropDownActive = useSelector((state) => state.isProductDropdown);

 const handleToggleClick = () => {
  dispatch(toggleMobileNav());
 };

 const triggerDropdown = () => {
  dispatch(toggleDropdown());
 };

 useEffect(() => {
  window.scrollTo(0, 0);
  return () => {
   dispatch(toggleCloseDropdown());
  };
 }, [dispatch]);

 return (
  <React.Fragment>
   <div className="container-fluid railway_domain_top p-0">
    <PublicHeader />
    {isDropDownActive && (
     <div className="col-md-12">
      <DropDownProducts />
     </div>
    )}
    <div
     className="d-flex justify-content-center align-items-center flex-column text-white text-center mt-5"
     style={{ height: "70%" }}
     data-aos="fade-up"
    >
     <div className="container-fluid d-md-flex justify-content-center align-items-center flex-column">
      <h1 className="railway_heading_info mb-0" data-aos="fade-up">
       Railways
      </h1>
      <div
       className="col-md-6 col-sm-12 p-3 para_header_info d-flex justify-content-center"
       data-aos="fade-up"
       data-aos-delay="400"
      >
       {RAILWAY_CONTENT_LANDINGPAGE}
      </div>
     </div>
    </div>
   </div>
   <section className="container-fluid d-md-flex justify-content-center bg-black">
    <div className="col-md-11 project_cloud">
     <div className="col-md-12"></div>
     <div className="col-12 d-md-flex justify-content-center align-items-center text-white railway_background rounded-3 p-3 mt-3">
      As trusted integrators and solution providers in the transportation sector, we specialize
      in Railway Signaling, network connectivity, sensor integration, and comprehensive
      dashboards for end-to-end applications. Our expertise lies in transferring signals
      rapidly and securely through encrypted transmission channels. We've engineered
      cutting-edge media converters to swiftly transmit sensor signals across clustered nodes,
      ensuring seamless connectivity and efficient operations.
     </div>
    </div>
   </section>
   <AlternateFooter />
  </React.Fragment>
 );
}

export default RailwayDomain;
