import Gnlogosvg from "../../assets/Svg/gnlogosvg";
import Indiansvg from "../../assets/Svg/indiarailwayssvg";
import Madeinindialogo from "../../assets/Svg/madeinindialogo_svg";
import Msmelogo from "../../assets/Svg/msmelogo_svg";
import StartupLogo from "../../assets/Svg/startuplogo_svg";
import Styles from "./domains/Logorow.module.css";

const Logo = () => {
 return (
  <div className={Styles.logoContainer} data-aos="fade-up">
   <div className={Styles.logoPart}>
    <StartupLogo />
    <Msmelogo />
    <Madeinindialogo />
    <Indiansvg />
    <Gnlogosvg />
   </div>
  </div>
 );
};

export default Logo;
