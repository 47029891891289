import React, { useEffect } from "react";
import "aos/dist/aos.css";
import { useDispatch, useSelector } from "react-redux";
import "./DefenceDomain.css";
import { toggleCloseDropdown, toggleDropdown, toggleMobileNav } from "../../../redux/actions";
import DropDownProducts from "../../../core/constants/DropdownProducts";
import AlternateFooter from "../AlternateFooter";
import PublicHeader from "../../../core/constants/PublicHeader";
import { DEFENCE_CONTENT_LANDINGPAGE } from "../../../core/constants/Constant";
import defenceOne from "../../../assets/homePage/Designer 1.png";
import defenceTwo from "../../../assets/homePage/Eng-dept.png";
import defenceThree from "../../../assets/homePage/sde3.png";

function DefenceDomain() {
 const dispatch = useDispatch();
 const isMobileNavActive = useSelector((state) => state.isMobileNavActive);
 const isDropDownActive = useSelector((state) => state.isProductDropdown);

 const handleToggleClick = () => {
  dispatch(toggleMobileNav());
 };

 const triggerDropdown = () => {
  dispatch(toggleDropdown());
 };

 useEffect(() => {
  window.scrollTo(0, 0);
  return () => {
   dispatch(toggleCloseDropdown());
  };
 }, [dispatch]);

 return (
  <React.Fragment>
   <div className="container-fluid defence_domain_top p-0">
    <PublicHeader />
    {isDropDownActive && (
     <div className="col-md-12">
      <DropDownProducts />
     </div>
    )}
    <div
     className="d-flex justify-content-center align-items-center flex-column text-white text-center mt-5"
     style={{ height: "70%" }}
     data-aos="fade-up"
    >
     <div className="container-fluid d-md-flex justify-content-center align-items-center flex-column">
      <h1 className="railway_heading_info mb-0" data-aos="fade-up">
       Defence
      </h1>
      <div
       className="col-md-6 col-sm-12 p-3 para_header_info d-flex justify-content-center"
       data-aos="fade-up"
       data-aos-delay="400"
      >
       Partnering with industry leaders, we provide advanced software and hardware solutions
       tailored for the Indian Defence sector. Our offerings include state-of-the-art optical
       media converters, rugged routers, modems, and control systems, designed to meet the
       rigorous demands of defence operations. As a leading and trusted partner, we deliver
       comprehensive solutions that embody the spirit of Indian defiance, addressing the unique
       needs of the defence industry and driving innovation to ensure excellence in operations
       across India and beyond.
      </div>
     </div>
    </div>
   </div>
   <section className="container-fluid d-md-flex justify-content-center bg-black">
    <div className="col-md-11 project_cloud">
     <div className="col-md-12"></div>
     <div className="col-12 text-center text-white railway_background rounded-3 p-3 mt-3">
      As trusted integrators and solution providers in the Indian Defence sector, we specialize
      in advanced signaling systems, sensor integration, and comprehensive software and
      hardware solutions for end-to-end applications. Our expertise lies in transferring
      signals rapidly and securely through encrypted transmission channels. We have engineered
      cutting-edge media converters and robust software platforms to swiftly transmit sensor
      signals across clustered nodes, ensuring seamless operation and efficient operations in
      defence applications.
     </div>
    </div>
   </section>
   <div className="container-fluid bg-black">
    <div className="row" data-aos="fade-up" data-aos-delay="400">
     <div className="col-12 text-center mb-5">
      <h1 className="serviceProvide_title">
       <span className="alternate_sub_color">Solutions</span> we provide
      </h1>
     </div>
     <div className="container-fluid d-md-flex justify-content-center align-items-center gap-3 mb-5 flex-column">
      <div
       className="col-md-12 p-2 rounded-2 d-md-flex justify-content-center align-items-start"
       style={{ border: "2px solid #404040" }}
      >
       <div className="col-md-7 p-3">
        <h2 className="flight-deck ">Flight deck monitoring system</h2>
        <p className="flight-deck-content col-11 pt-4">
         The Monitoring System is an advanced solution designed to ensure real-time oversight
         of critical fire safety mechanisms, specifically focusing on the fire hydrant system.
         It provides comprehensive, Centralised monitoring, displaying key data in a unified
         software interface. This allows operators to maintain full visibility and control over
         the entire fire hydrant system in one glance.
        </p>
        <div className="pt-4">
         <div className="col-md-10 d-md-flex justify-content-start align-items-center gap-3">
          <div className="flight-deck-content-two mt-2">Instant Alerts</div>
          <div className="flight-deck-content-two mt-2">Centralized Monitoring</div>
          <div className="flight-deck-content-two mt-2">Real-time pressure monitoring</div>
         </div>
         <div className="col-md-8 d-md-flex justify-content-start align-items-center pt-3 gap-3">
          <div className="flight-deck-content-two mt-2">User-Friendly Interface</div>
          <div className="flight-deck-content-two mt-2">Data Logging</div>
          <div className="flight-deck-content-two mt-2">Highly Secured</div>
         </div>
        </div>
       </div>
       <div className="col-md-5 p-3 d-flex justify-content-center align-items-center">
        <img src={defenceOne} alt="Description" className="img-fluid rounded-3" />
       </div>
      </div>
      <div
       className="col-md-12 p-2 rounded-2 d-md-flex justify-content-center align-items-start"
       style={{ border: "2px solid #404040" }}
      >
       <div className="col-md-5 p-3 d-flex justify-content-center align-items-center">
        <img src={defenceTwo} alt="Description" className="img-fluid rounded-3" />
       </div>
       <div className="col-md-7 p-3">
        <h2 className="flight-deck ">Engineering Department</h2>
        <p className="flight-deck-content col-12 pt-4">
         At the Aviation Engineering Department, we provide state-of-the-art software solutions
         for managing and monitoring operational systems. Our technology supports the real-time
         tracking of various critical systems, allowing for enhanced service monitoring,
         operational status updates, and log maintenance to ensure the smooth functioning of
         complex infrastructures.
        </p>
        <div className="pt-4">
         <div className="col-md-9 d-md-flex justify-content-start align-items-center gap-3">
          <div className="flight-deck-content-two mt-2">Real-Time System Monitoring</div>
          <div className="flight-deck-content-two mt-2">Service Monitoring and Scheduling</div>
         </div>
         <div className="col-md-10 d-md-flex justify-content-start align-items-center gap-3 pt-3">
          <div className="flight-deck-content-two mt-2">Operational Log Maintenance</div>
          <div className="flight-deck-content-two mt-2">Role-Based Access</div>
          <div className="flight-deck-content-two mt-2">User-Friendly Interface</div>
         </div>
         <div className="col-md-8 d-md-flex justify-content-start align-items-center pt-3 gap-3">
          <div className="flight-deck-content-two mt-2">Advanced Alerting System</div>
          <div className="flight-deck-content-two mt-2">Data Analytics and Reporting</div>
         </div>
        </div>
       </div>
      </div>
      <div
       className="col-md-12 p-2 rounded-2 d-md-flex justify-content-center align-items-start"
       style={{ border: "2px solid #404040" }}
      >
       <div className="col-md-7 p-3">
        <h2 className="flight-deck ">AI Based Inventory Software</h2>
        <p className="flight-deck-content col-11 pt-4">
         Managing tools effectively is essential for maintaining operational efficiency in any
         organization. Our Tools Inventory Software is designed to provide a holistic solution
         for the auditing, status analysis, and management of tools and equipment. This system
         helps organizations streamline their inventory processes, ensuring that every tool is
         accounted for, maintained, and utilized to its fullest potential.
        </p>
        <div className="pt-4">
         <div className="col-md-9 d-md-flex justify-content-start align-items-center gap-3">
          <div className="flight-deck-content-two mt-2">Status Management and Analysis</div>
          <div className="flight-deck-content-two mt-2">Inventory Management System</div>
         </div>
         <div className="col-md-10 d-md-flex justify-content-start align-items-center pt-3 gap-3">
          <div className="flight-deck-content-two mt-2">Role-Based Access</div>
          <div className="flight-deck-content-two mt-2">User-Friendly Interface</div>
          <div className="flight-deck-content-two mt-2">Advanced Alerting System</div>
         </div>
        </div>
       </div>
       <div className="col-md-5 p-3 d-flex justify-content-center align-items-center">
        <img src={defenceThree} alt="Description" className="img-fluid rounded-3" />
       </div>
      </div>
     </div>
    </div>
   </div>
   <AlternateFooter />
  </React.Fragment>
 );
}

export default DefenceDomain;
