import React, { useEffect } from "react";
import "../../componentStyles/landingPageStyle/Footer.css";
import Logo from "../../assets/homePage/company_logo.png";
import linkedInLogo from "../../assets/aboutUs/devicon_linkedin.png";
import emailLogo from "../../assets/aboutUs/Vector.png";
import { FaLocationDot } from "react-icons/fa6";
import { Link } from "react-router-dom";
import { scrollToDiv } from "../../core/constants/Constant";
import { useNavigate } from "react-router-dom";
import chennaiOfficeLocation from "../../assets/chennai_office_location1.jpg";
import pondicherryOfficeLocation from "../../assets/pondicherry_office_location1.jpg";
import karwarOfficeLocation from "../../assets/karwaroofice-loc.jpg";

function AlternateFooter(props) {
 const styles = { textDecoration: "none" };
 const navigate = useNavigate();

 const onReloadHome = () => {
  navigate("/");
  window.location.reload();
 };

 // const chennaiLocation = () => {
 //   props.chennaiLocation;
 // };
 // const pondyLocation = () => {
 //   props.pondyLocation;
 // };

 return (
  <div
   id="footer"
   className="col-md-12 d-flex justify-content-between align-items-start flex-wrap"
  >
   <div className="col-md-4 p-3 d-flex justify-content-between flex-column footer_details_company">
    <div className="col-md-4">
     <img
      src={Logo}
      alt="footer_logo"
      className="image-fluid footer_logo"
      onClick={onReloadHome}
     />
    </div>
    <div className="col-12 d-flex justify-content-between align-items-center mt-2 gap-2 ">
     <Link
      className="col-4 image-container"
      style={styles}
      to="/aboutus-page/#our_location_aboutus"
      onClick={props.pondyLocation}
     >
      <div>
       <img
        src={chennaiOfficeLocation}
        className="img-fluid office-location-img rounded-2"
        alt="chennai_office"
        style={{ cursor: "pointer" }}
       />
      </div>
      <div className="location_text d-flex justify-content-start gap-1">
       <div className="d-flex justify-content-center align-items-center">
        {/* <FaLocationDot size={12} color="#fff" className="hover-text" /> */}
       </div>
       <div className="hover-text">Puducherry Office</div>
      </div>
     </Link>
     <Link
      className="col-4 image-container"
      style={styles}
      to="/aboutus-page/#our_location_aboutus"
      onClick={props.chennaiLocation}
     >
      <div>
       <img
        src={pondicherryOfficeLocation}
        className="img-fluid office-location-img rounded-2"
        alt="chennai_office"
        style={{ cursor: "pointer" }}
       />
      </div>
      <div className="location_text d-flex justify-content-start gap-1">
       <div className="d-flex justify-content-center align-items-center">
        {/* <FaLocationDot size={12} color="#fff" className="hover-text"/> */}
       </div>
       <div className=" hover-text">Chennai Office</div>
      </div>
     </Link>

     <Link
      className="col-4 image-container"
      style={styles}
      to="/aboutus-page/#our_location_aboutus"
      onClick={props.chennaiLocation}
     >
      <div>
       <img
        src={karwarOfficeLocation}
        className="img-fluid office-location-img rounded-2"
        alt="karwar_office"
        style={{ cursor: "pointer" }}
       />
      </div>
      <div className="location_text d-flex justify-content-start gap-1">
       <div className="d-flex justify-content-center align-items-center">
        {/* <FaLocationDot size={12} color="#fff" className="hover-text"/> */}
       </div>
       <div className=" hover-text">Karwar Office</div>
      </div>
     </Link>
    </div>

    <div className="col-md-5 d-flex justify-content-start align-items-center flex-row mt-4">
     <a
      style={{ cursor: "pointer" }}
      href="https://in.linkedin.com/company/iinvsys"
      target="_blank"
     >
      <img src={linkedInLogo} height={34} width={26} className="img-fluid" />
     </a>
     <a
      style={{ cursor: "pointer" }}
      onClick={() => {
       window.open("mailto:support@iinvsys.com");
      }}
     >
      <img src={emailLogo} height={34} width={26} className="img-fluid mx-3" />
     </a>
    </div>
   </div>
   <div className="col-md-2 p-3 d-flex justify-content-center align-items-start flex-column footer_details">
    <div className="col-md-12" style={{ height: "80%" }}>
     <div className="col-md-12">
      <h6 className="footer_sub_head">Home</h6>
     </div>
     <div className="col-md-12 d-flex justify-content-start align-items-start flex-column">
      <Link to="/#vision_and_mission" style={styles}>
       <p className="footer_sub">Our Vision and Mission</p>
      </Link>
      <Link to="/#domain" style={styles}>
       <p className="footer_sub">Domains We Focus On</p>
      </Link>
      <Link to="/#service_section" style={styles}>
       <p className="footer_sub">Solutions we provide</p>
      </Link>
      <Link to={"/contact-us"} style={styles}>
       <p className="footer_sub">Contact Us</p>
      </Link>
      <Link to={"/privacy_policy"} style={styles}>
       <p className="footer_sub">Privacy Policy</p>
      </Link>
     </div>
    </div>
   </div>

   {/* Products is Disabled as per the input given
Changes done on 20/12/2024 by Muthuselvam */}

   {/* <div className="col-md-2 p-3 d-flex justify-content-center align-items-start flex-column footer_details">
    <div className="col-md-12" style={{ height: "80%" }}>
     <div className="col-md-2">
      <h6 className="footer_sub_head">Products</h6>
     </div>
     <div className="col-md-9 d-flex justify-content-start align-items-start flex-column">
      <Link to={"/smazer-product"} style={styles}>
       <p className="footer_sub">SmaZer</p>
      </Link>
      <Link to={"/szephyr-product"} style={styles}>
       <p className="footer_sub">sZephyr</p>
      </Link>
      <Link to={"/vidyuRaksha-product"} style={styles}>
       <p className="footer_sub">VidyuRaksha</p>
      </Link>
     </div>
    </div>
   </div> */}

   <div className="col-md-2 p-3 d-flex justify-content-center align-items-start flex-column footer_details">
    <div className="col-md-12" style={{ height: "80%" }}>
     <div className="col-md-12">
      <h6 className="footer_sub_head">Domains</h6>
     </div>
     <div className="col-md-12 d-flex justify-content-start align-items-start flex-column">
      <Link to={"/iot"} style={styles}>
       <p className="footer_sub">IoT / IIoT</p>
      </Link>
      {/* <Link to={"/cloud"} style={styles}>
              <p className="footer_sub">Cloud</p>
            </Link> */}
      <Link to={"/railway"} style={styles}>
       <p className="footer_sub">Railways</p>
      </Link>
      <Link to={"/space"} style={styles}>
       <p className="footer_sub">Space</p>
      </Link>
      <Link to={"/defence"} style={styles}>
       <p className="footer_sub">Defence</p>
      </Link>
     </div>
    </div>
   </div>
   <div className="col-md-2 p-3 d-md-flex justify-content-center align-items-start flex-column footer_details">
    <div className="col-md-12" style={{ height: "80%" }}>
     <div className="col-md-12">
      <h6 className="footer_sub_head">About Us</h6>
     </div>
     <Link
      style={styles}
      to="/aboutus-page/#our_location_aboutus"
      className="col-md-12 d-flex justify-content-start align-items-start flex-column"
     >
      {/* <p className="footer_sub">Our core values</p> */}
      {/* <p className="footer_sub">Our Team</p>
            <p className="footer_sub">Careers</p>
            <p className="footer_sub">Life at IInvsys</p> */}
      <p className="footer_sub">Locate Us</p>
     </Link>
    </div>
   </div>
  </div>
 );
}

export default AlternateFooter;
