import React, { useEffect } from "react";
import PublicHeader from "../../../core/constants/PublicHeader";
import "./IotDomain.css";
import { useDispatch, useSelector } from "react-redux";
import { toggleCloseDropdown } from "../../../redux/actions";
import DropDownProducts from "../../../core/constants/DropdownProducts";
import AlternateFooter from "../AlternateFooter";
import { IOT_CONTENT_LANDINGPAGE } from "../../../core/constants/Constant";
import inHouseOne from "../../../assets/homePage/Group 978aa.png";
import inHouseTwo from "../../../assets/homePage/Group 981sd.png";
import inHouseThree from "../../../assets/homePage/display-control.png";
import inHouseFour from "../../../assets/homePage/Group 982scd.png";
import inHouseFive from "../../../assets/homePage/Group 974sacfd.png";
import inHouseSix from "../../../assets/homePage/multisensor.png";
import inHouseSeven from "../../../assets/homePage/Picture12Z.png";
import inHouseEight from "../../../assets/homePage/power-supply.png";
import inHouseNine from "../../../assets/homePage/Picture13ss.png";
import inHouseten from "../../../assets/homePage/Group 980adf.png";

function IOTDomain() {
  const dispatch = useDispatch();
  const isDropDownActive = useSelector((state) => state.isProductDropdown);

  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {
      dispatch(toggleCloseDropdown());
    };
  }, [dispatch]);

  return (
    <React.Fragment>
      <div className="container-fluid iot_domain_top p-0">
        <PublicHeader />
        {isDropDownActive && (
          <div className="col-md-12">
            <DropDownProducts />
          </div>
        )}
        <div
          className="d-flex justify-content-center align-items-center flex-column text-white text-center mt-5"
          style={{ height: "70%" }}
          data-aos="fade-up"
        >
          <div className="container-fluid d-md-flex justify-content-center align-items-center flex-column">
            <div className="iot_content_text">Internet Of Things</div>
            <h1 className="iot_heading_info mb-0" data-aos="fade-up">
              <span>I</span>
              <span>O</span>
              <span>T</span>
            </h1>
            <div
              className="col-md-6 col-sm-12 p-3 para_header_info d-flex justify-content-center"
              data-aos="fade-up"
              data-aos-delay="400"
            >
              {IOT_CONTENT_LANDINGPAGE}
            </div>
          </div>
        </div>
      </div>
      <section className="container-fluid d-md-flex justify-content-center bg-black">
        <div className="col-md-11 project_cloud">
          <div className="col-md-12"></div>
          <div className="col-12 d-md-flex justify-content-center align-items-center text-white railway_background rounded-3 p-3 mt-3">
            We pride ourselves on providing comprehensive, in-house, indigenous design and
            manufacturing solutions. Our expertise spans software, electrical, electronic, and
            mechanical engineering. We deliver custom-built solutions that cater to diverse
            industry needs, ensuring quality, innovation, and reliability at every step. From
            concept to completion, our team of skilled professionals uses advanced technology
            to create products and systems that meet the highest standards, helping businesses
            achieve their goals with cutting-edge engineering and design.
          </div>
        </div>
      </section>
      <div
        className="d-flex justify-content-center align-items-center flex-column text-white text-center bg-black"
        style={{ height: "70%" }}
      >
        <div className="container-fluid d-md-flex justify-content-center align-items-center flex-column">
          <h1 className="col-md-9 in_house_heading_info mb-0" data-aos="fade-up">
            In-House-Indigenous Design and Manufacturing
          </h1>
          <div
            className="col-md-10 col-sm-12 p-3 in_house_text d-flex justify-content-center pt-4"
            data-aos="fade-up"
            data-aos-delay="400"
          >
            We pride ourselves on providing comprehensive, in-house, indigenous design and
            manufacturing solutions. Our expertise spans software, electrical, electronic, and
            mechanical engineering. We deliver custom-built solutions that cater to diverse
            industry needs, ensuring quality, innovation, and reliability at every step. From
            concept to completion, our team of skilled professionals uses advanced technology
            to create products and systems that meet the highest standards, helping businesses
            achieve their goals with cutting-edge engineering and design.
          </div>
        </div>
      </div>
      <div className="container-fluid bg-black pt-5">
        <div className="row" data-aos="fade-up" data-aos-delay="400">
          <div className="col-12 text-center mb-5">
            <h1 className="serviceProvide_title">
              <span className="alternate_sub_color">Solutions</span> we provide
            </h1>
          </div>
          <div className="container-fluid d-md-flex justify-content-center align-items-center gap-3 mb-5 flex-column">
            <div
              className="col-md-12 p-2 rounded-2 d-md-flex justify-content-center align-items-start"
              style={{ border: "2px solid #404040" }}
            >
              <div className="col-md-5 p-3 d-flex justify-content-center align-items-center">
                <img
                  src={inHouseFour}
                  alt="Description"
                  className="img-fluid"
                  width={382}
                  height={355}
                />
              </div>
              <div className="col-md-7 p-3">
                <h2 className="flight-deck ">Smart Water Bottle</h2>
                <p className="flight-deck-content col-11 pt-4">
                  Our Smart Water Bottle is designed and manufactured with cutting-edge
                  technology to help you stay hydrated more effectively. Equipped with advanced
                  sensors, it tracks your water intake in real-time and syncs with a dedicated
                  app to offer personalized hydration reminders and goals. The bottle features
                  sleek, durable materials and a rechargeable battery, making it both stylish
                  and eco-friendly. With intuitive LED indicators and smart notifications, our
                  Smart Water Bottle ensures that maintaining proper hydration is effortless
                  and integrated into your daily routine.
                </p>
                <div className="pt-4">
                  <div className="col-md-9 d-md-flex justify-content-start align-items-center gap-3">
                    <div className="flight-deck-content-two mt-2">Stay Hydrated</div>
                    <div className="flight-deck-content-two mt-2">Smart Device</div>
                    <div className="flight-deck-content-two mt-2">Drink Water</div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-md-12 p-2 rounded-2 d-md-flex justify-content-center align-items-start"
              style={{ border: "2px solid #404040" }}
            >
              <div className="col-md-7 p-3">
                <h2 className="flight-deck ">Ethernet RING based Protocol Media Convertor</h2>
                <p className="flight-deck-content col-11 pt-4">
                  The RING networking enabled Protocol Media converter offers high
                  Compatibility, Reliability, Cost-effectiveness, Flexibility, and High
                  Scalability, allowing the conversion of all legacy systems to modern systems.
                </p>
                <div className="pt-4">
                  <div className="col-md-10 d-md-flex justify-content-start align-items-center gap-3">
                    <div className="flight-deck-content-two mt-2">RING Networking</div>
                    <div className="flight-deck-content-two mt-2">
                      Legacy Serial to Ethernet
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-5 p-3 d-flex justify-content-center align-items-center mt-3">
                <img
                  src={inHouseOne}
                  alt="Description"
                  className="img-fluid"
                  height={244}
                  width={268}
                />
              </div>
            </div>
            <div
              className="col-md-12 p-2 rounded-2 d-md-flex justify-content-center align-items-start"
              style={{ border: "2px solid #404040" }}
            >
              <div className="col-md-5 p-3 d-flex justify-content-center align-items-center">
                <img
                  src={inHouseTwo}
                  alt="Description"
                  className="img-fluid"
                  height={285}
                  width={435}
                />
              </div>
              <div className="col-md-7 p-3">
                <h2 className="flight-deck ">Smart Electrical Panel</h2>
                <p className="flight-deck-content col-11 pt-4">
                  The Smart Electrical Panel is designed with dual electrical line energy
                  monitoring, which enables precise tracking of energy consumption. It supports
                  remote control and smart management through a mobile application.
                  Additionally, it allows for multiple users and devices, making it possible to
                  create personalized scenes. Each Panel can able to accommodate up to 6 loads.
                </p>
                <div className="pt-4">
                  <div className="col-md-9 d-md-flex justify-content-start align-items-center gap-3">
                    <div className="flight-deck-content-two mt-2">RAW & Invertor Power</div>
                    <div className="flight-deck-content-two mt-2">
                      6 Loads (Five 5A load + One 15A Load)
                    </div>
                  </div>
                  <div className="col-md-12 d-md-flex justify-content-start align-items-center gap-3 pt-3">
                    <div className="flight-deck-content-two mt-2">
                      Real Time Power Monitoring
                    </div>
                    <div className="flight-deck-content-two mt-2">Remote Control</div>
                    <div className="flight-deck-content-two mt-2"> Android & IOS</div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-md-12 p-2 rounded-2 d-md-flex justify-content-center align-items-start"
              style={{ border: "2px solid #404040" }}
            >
              <div className="col-md-7 p-3">
                <h2 className="flight-deck ">VidyuRaksha2.O</h2>
                <p className="flight-deck-content col-11 pt-4">
                  VidyaRaksha 2.0 is a robust industrial Grade electrical socket solution
                  designed to meet the demanding needs of industrial settings with its 15A
                  current rating and support for up to 4000W it is capable of handling wide
                  range of industrial equipment's its ability to guard against inflicting
                  voltages and preventing shots circuit indicates a strong focus on both human
                  safety and equipment reliability.
                </p>
                <div className="pt-2">
                  <div className="col-md-9 d-md-flex justify-content-start align-items-center gap-3">
                    <div className="flight-deck-content-two mt-2">Rugged Design</div>
                    <div className="flight-deck-content-two mt-2">Centralised monitoring</div>
                  </div>
                  <div className="col-md-10 d-md-flex justify-content-start align-items-center pt-3 gap-3">
                    <div className="flight-deck-content-two mt-2">Role-Based Access</div>
                    <div className="flight-deck-content-two mt-2">User-Friendly Interface</div>
                    <div className="flight-deck-content-two mt-2">
                      Advanced Alerting System
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-5 p-3 d-flex justify-content-center align-items-center">
                <img
                  src={inHouseFive}
                  alt="Description"
                  className="img-fluid"
                  height={325}
                  width={394}
                />
              </div>
            </div>
            <div
              className="col-md-12 p-2 rounded-2 d-md-flex justify-content-center align-items-start"
              style={{ border: "2px solid #404040" }}
            >
              <div className="col-md-5 p-3 d-flex justify-content-center align-items-center mt-4">
                <img
                  src={inHouseSix}
                  alt="Description"
                  className="img-fluid"
                  width={357}
                  height={276}
                />
              </div>
              <div className="col-md-7 p-3">
                <h2 className="flight-deck ">Multi sensor controller </h2>
                <p className="flight-deck-content col-11 pt-4">
                  Multi-sensor controller equipped with RS485, CAN, 4-20mA, and ADC
                  capabilities is a versatile device designed to handle a wide range of sensor
                  inputs and communication protocols. It provides a flexible solution for
                  various industrial automation, building automation, and environmental
                  monitoring applications.
                </p>
                <div className="pt-4">
                  <div className="col-md-9 d-md-flex justify-content-start align-items-center gap-3">
                    <div className="flight-deck-content-two mt-2">
                      Real-Time System Monitoring
                    </div>
                    <div className="flight-deck-content-two mt-2">
                      Service Monitoring and Scheduling
                    </div>
                  </div>
                  <div className="col-md-10 d-md-flex justify-content-start align-items-center gap-3 pt-3">
                    <div className="flight-deck-content-two mt-2">
                      Operational Log Maintenance
                    </div>
                    <div className="flight-deck-content-two mt-2">Role-Based Access</div>
                    <div className="flight-deck-content-two mt-2">User-Friendly Interface</div>
                  </div>
                  <div className="col-md-8 d-md-flex justify-content-start align-items-center pt-3 gap-3">
                    <div className="flight-deck-content-two mt-2">
                      Advanced Alerting System
                    </div>
                    <div className="flight-deck-content-two mt-2">
                      Data Analytics and Reporting
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-md-12 p-2 rounded-2 d-md-flex justify-content-center align-items-start"
              style={{ border: "2px solid #404040" }}
            >
              <div className="col-md-7 p-3">
                <h2 className="flight-deck ">Industrial Rugged Control Panel</h2>
                <p className="flight-deck-content col-11 pt-4">
                  An industrial rugged control panel equipped with SCADA, sensor network,
                  display, networking, Ethernet, RS485, CAN, real-time alerts, a multi-master
                  system, a multi-display system, and a database provides a comprehensive and
                  advanced solution for monitoring, controlling, and managing industrial
                  processes. However, to ensure the security and integrity of the system, it is
                  essential to incorporate robust security measures.
                </p>
                <div className="pt-4">
                  <div className="col-md-9 d-md-flex justify-content-start align-items-center gap-3">
                    <div className="flight-deck-content-two mt-2">
                      Status Management and Analysis
                    </div>
                    <div className="flight-deck-content-two mt-2">
                      High performance processor
                    </div>
                  </div>
                  <div className="col-md-10 d-md-flex justify-content-start align-items-center pt-3 gap-3">
                    <div className="flight-deck-content-two mt-2">Role-Based Access</div>
                    <div className="flight-deck-content-two mt-2">Redundant Power Supply</div>
                  </div>
                  <div className="col-md-10 d-md-flex justify-content-start align-items-center pt-3 gap-3">
                    <div className="flight-deck-content-two mt-2">Ethernet</div>
                    <div className="flight-deck-content-two mt-2">Data Network</div>
                  </div>
                </div>
              </div>
              <div className="col-md-5 p-3 d-flex justify-content-center align-items-center">
                <img
                  src={inHouseSeven}
                  alt="Description"
                  className="img-fluid"
                  height={266}
                  width={200}
                />
              </div>
            </div>
            <div
              className="col-md-12 p-2 rounded-2 d-md-flex justify-content-center align-items-start"
              style={{ border: "2px solid #404040" }}
            >
              <div className="col-md-5 p-3 d-flex justify-content-center align-items-center mt-5">
                <img
                  src={inHouseEight}
                  alt="Description"
                  className="img-fluid"
                  width={357}
                  height={248}
                />
              </div>
              <div className="col-md-7 p-3">
                <h2 className="flight-deck ">Redundancy Power Supply</h2>
                <p className="flight-deck-content col-11 pt-4">
                  A redundancy power supply converter with a backup power line, dual 230V AC
                  input, and a single 24V DC output is a highly reliable and fail-safe solution
                  for critical applications. This type of system provides uninterrupted power
                  supply even in the event of multiple power failures or system component
                  failures.
                </p>
                <div className="pt-4">
                  <div className="col-md-9 d-md-flex justify-content-start align-items-center gap-3">
                    <div className="flight-deck-content-two mt-2">Fail-Safe Power Supply</div>
                    <div className="flight-deck-content-two mt-2">Redundancy</div>
                  </div>
                  <div className="col-md-10 d-md-flex justify-content-start align-items-center gap-3 pt-3">
                    <div className="flight-deck-content-two mt-2">
                      Switch Mode power supply
                    </div>
                    <div className="flight-deck-content-two mt-2">Reliable</div>
                    <div className="flight-deck-content-two mt-2">Rugged</div>
                  </div>
                  <div className="col-md-8 d-md-flex justify-content-start align-items-center pt-3 gap-3">
                    <div className="flight-deck-content-two mt-2">Wide Range</div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-md-12 p-2 rounded-2 d-md-flex justify-content-center align-items-start"
              style={{ border: "2px solid #404040" }}
            >
              <div className="col-md-7 p-3">
                <h2 className="flight-deck ">Indication Panel Display</h2>
                <p className="flight-deck-content col-11 pt-4">
                  An indication panel display is a visual interface that provides information
                  about the status of various systems, processes, or equipment. It typically
                  includes individual LED indicators, alarm status indicators, buzzer control,
                  automation capabilities, and integration with a control system.
                </p>
                <div className="pt-4">
                  <div className="col-md-9 d-md-flex justify-content-start align-items-center gap-3">
                    <div className="flight-deck-content-two mt-2">Buzzer/Hooter</div>
                    {/* <div className="flight-deck-content-two mt-2">Buzzer/Hooter</div> */}
                  </div>
                  <div className="col-md-10 d-md-flex justify-content-start align-items-center pt-3 gap-3">
                    <div className="flight-deck-content-two mt-2">Stop Button</div>
                    <div className="flight-deck-content-two mt-2">LED Indicators</div>
                    <div className="flight-deck-content-two mt-2">RS485</div>
                  </div>
                </div>
              </div>
              <div className="col-md-5 p-3 d-flex justify-content-center align-items-center">
                <img
                  src={inHouseNine}
                  alt="Description"
                  className="img-fluid"
                  height={329}
                  width={213}
                />
              </div>
            </div>
            {/* <div
              className="col-md-12 p-2 rounded-2 d-md-flex justify-content-center align-items-start"
              style={{ border: "2px solid #404040" }}
            >
              <div className="col-md-5 p-3 d-flex justify-content-center align-items-center">
                <img
                  src={inHouseten}
                  alt="Description"
                  className="img-fluid"
                  height={232}
                  width={319}
                />
              </div>
              <div className="col-md-7 p-3">
                <h2 className="flight-deck">FRMC PP</h2>
                <p className="flight-deck-content col-11 pt-4">
                  Managing tools effectively is essential for maintaining operational
                  efficiency in any organization. Our Tools Inventory Software is designed to
                  provide a holistic solution for the auditing, status analysis, and management
                  of tools and equipment. This system helps organizations streamline their
                  inventory processes, ensuring that every tool is accounted for, maintained,
                  and utilized to its fullest potential.
                </p>
                <div className="pt-4">
                  <div className="col-md-9 d-md-flex justify-content-start align-items-center gap-3">
                    <div className="flight-deck-content-two mt-2">
                      Status Management and Analysis
                    </div>
                    <div className="flight-deck-content-two mt-2">
                      Inventory Management System
                    </div>
                  </div>
                  <div className="col-md-10 d-md-flex justify-content-start align-items-center pt-3 gap-3">
                    <div className="flight-deck-content-two mt-2">Role-Based Access</div>
                    <div className="flight-deck-content-two mt-2">User-Friendly Interface</div>
                    <div className="flight-deck-content-two mt-2">Power Control System</div>
                  </div>
                </div>
              </div>
            </div> */}
            <div
              className="col-md-12 p-2 rounded-2 d-md-flex justify-content-center align-items-start"
              style={{ border: "2px solid #404040" }}
            >
              <div className="col-md-5 p-3 d-flex justify-content-center align-items-center mt-4">
                <img
                  src={inHouseThree}
                  alt="Description"
                  className="img-fluid"
                  height={316}
                  width={229}
                />
              </div>
              <div className="col-md-7 p-3">
                <h2 className="flight-deck ">Display control panel</h2>
                <p className="flight-deck-content col-11 pt-4">
                  At the Aviation Engineering Department, we provide state-of-the-art software
                  solutions for managing and monitoring operational systems. Our technology
                  supports the real-time tracking of various critical systems, allowing for
                  enhanced service monitoring, operational status updates, and log maintenance
                  to ensure the smooth functioning of complex infrastructures.
                </p>
                <div className="pt-4">
                  <div className="col-md-9 d-md-flex justify-content-start align-items-center gap-3">
                    <div className="flight-deck-content-two mt-2">
                      Real-Time System Monitoring
                    </div>
                    <div className="flight-deck-content-two mt-2">
                      Service Monitoring and Scheduling
                    </div>
                  </div>
                  <div className="col-md-10 d-md-flex justify-content-start align-items-center gap-3 pt-3">
                    <div className="flight-deck-content-two mt-2">
                      Operational Log Maintenance
                    </div>
                    <div className="flight-deck-content-two mt-2">Role-Based Access</div>
                    <div className="flight-deck-content-two mt-2">User-Friendly Interface</div>
                  </div>
                  <div className="col-md-8 d-md-flex justify-content-start align-items-center pt-3 gap-3">
                    <div className="flight-deck-content-two mt-2">
                      Advanced Alerting System
                    </div>
                    <div className="flight-deck-content-two mt-2">
                      Data Analytics and Reporting
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AlternateFooter />
    </React.Fragment>
  );
}

export default IOTDomain;
