import React, { useState, useEffect, useRef } from "react";
import "../../componentStyles/landingPageStyle/Header.css";
import { IoMdHome } from "react-icons/io";
import { MdOutlineShoppingCart } from "react-icons/md";
import { RxHamburgerMenu } from "react-icons/rx";
import { MdClose } from "react-icons/md";
import Logo from "../../assets/homePage/company_logo.png";
import Visions from "./Visions";
import Support from "./Support";
import Footer from "./Footer";
import { Link, NavLink, useLocation } from "react-router-dom";
import MobileNavigationHeader from "../../core/constants/MobileNavHeader";
import { useDispatch, useSelector } from "react-redux";
import { toggleCloseDropdown, toggleDropdown, toggleMobileNav } from "../../redux/actions";
import AlternateFooter from "./AlternateFooter";
import DropDownProducts from "../../core/constants/DropdownProducts";
// import { IoCloseSharp } from "react-icons/io5";
import heroPageVideo from "../../assets/CinematicHeroPage.mp4";
import PublicHeader from "../../core/constants/PublicHeader";
import HeaderComponent from "../../core/constants/HeaderComponent";
import Spinner from "../../core/LoadingSpinner/Spinner";
import Domain from "./Domain";
import { scrollToDiv } from "../../core/constants/Constant";
import Services from "./Services";
import { FaPause, FaPlay } from "react-icons/fa";
import { useMediaQuery } from "react-responsive";
import StartupLogo from "../../assets/Svg/startuplogo_svg";
import Msmelogo from "../../assets/Svg/msmelogo_svg";
import Madeinindialogo from "../../assets/Svg/madeinindialogo_svg";
import Logopage from "./Logorow";

function Header() {
 const isDropDownActive = useSelector((state) => state.isProductDropdown);
 const [isLoading, setIsLoading] = useState(true);
 const videoRef = useRef();
 const [isPlaying, setIsPlaying] = useState(false);
 const location = useLocation();

 const handlePlay = () => {
  if (isPlaying) {
   videoRef.current.play();
  } else {
   videoRef.current.pause();
  }
  setIsPlaying(!isPlaying);
 };

 useEffect(() => {
  // const timeout = setTimeout(() => {
  //   setIsLoading(false);
  // }, 3000);
  if (location.hash) {
   const element = document.getElementById(location.hash.substring(1));
   console.log(location.hash.substring(1));
   if (element) {
    element.scrollIntoView({ behavior: "smooth" });
   }
  }

  // return () => clearTimeout(timeout);
 }, [location]);

 return (
  <React.Fragment>
   {/* {isLoading ? (
        <Spinner />
      ) : ( */}
   <div id="landing_page">
    <PublicHeader />
    <div className="container-fluid header_top">
     {/* <HeaderComponent /> */}
     {isDropDownActive && <DropDownProducts />}
     <div
      className="container-fluid d-flex justify-content-center align-items-center flex-column text-white text-center pb-2"
      style={{ height: "70%" }}
      data-aos="fade-up"
     ></div>
     <div className="container-fluid d-flex justify-content-center align-items-center">
      <div className="col-md-10">
       <div className="row d-flex justify-content-center align-items-center">
        <div className="col-12">
         <div
          className="video_container d-flex justify-content-center align-items-center"
          onClick={handlePlay}
         >
          <video
           className="video_player rounded-3"
           style={{ width: "85%" }}
           playsInline
           fluid={false}
           // controls
           autoPlay
           loop
           muted={true}
           ref={videoRef}
          >
           <source src={heroPageVideo} type="video/mp4" />
          </video>
          <div className="play_icon">
           {isPlaying ? (
            <FaPlay size={20} color="#fff" onClick={handlePlay} />
           ) : (
            <FaPause size={20} color="#fff" onClick={handlePlay} />
           )}
          </div>
         </div>
         {/* {videoPlayBackViewer} */}
        </div>
       </div>
      </div>
     </div>
     <div id="vision_and_mission">
      <Visions />
     </div>

     <Logopage />

     <div id="domain">
      <Domain />
     </div>
    </div>
    <div id="service_section">
     <Services />
    </div>
    <div id="support_section">
     <Support />
    </div>
    <AlternateFooter />
   </div>
   {/* )} */}
  </React.Fragment>
 );
}

export default Header;
